import React, { ReactFragment } from "react";
import Router from "next/router";
import { MoreWaysToSaveHouseComposition } from "./MoreWaysToSaveHouseComposition";
import { MoreWaysToSaveCards } from "./MoreWaysToSaveCards";
import Carousel from "elements/Carousel";

export type MoreWaysToSaveService =
	| "appeal"
	| "appeal-no-click"
	| "exemption"
	| "insurance"
	| "bill-reduction"
	| "georgia-freeze"
	| "georgia-appeal-rule"
	| "new-york-exemption"
	| "new-york-nassau"
	| "new-york-scar";

export const MoreWaysToSaveSection = ({
	target,
	services = ["appeal", "exemption", "insurance", "bill-reduction"],
	title,
}: {
	target?: string;
	services?: MoreWaysToSaveService[];
	title?: ReactFragment | string;
}) => {
	const [activeWaysToSaveCarouselCard, setActiveWaysToSaveCarouselCard] =
		React.useState<MoreWaysToSaveService>("appeal");

	const [activeWaysToSaveCompositionCard, setActiveWaysToSaveCompositionCard] =
		React.useState<MoreWaysToSaveService | undefined>();

	const serviceMap: Record<MoreWaysToSaveService, JSX.Element> = {
		appeal: (
			<MoreWaysToSaveCards.Appeal
				target={target}
				className="place-tl"
				onClick={() => Router.push("/appeals")}
				onMouseOver={() => setActiveWaysToSaveCompositionCard("appeal")}
				onMouseLeave={() => setActiveWaysToSaveCompositionCard(undefined)}
			/>
		),
		"appeal-no-click": (
			<MoreWaysToSaveCards.Appeal
				target={target}
				className="place-tl"
				hideCta={true}
			/>
		),
		exemption: (
			<MoreWaysToSaveCards.Exemption
				target={target}
				className="place-tr"
				onClick={() => Router.push("/exemptions")}
				onMouseOver={() => setActiveWaysToSaveCompositionCard("exemption")}
				onMouseLeave={() => setActiveWaysToSaveCompositionCard(undefined)}
			/>
		),
		insurance: (
			<MoreWaysToSaveCards.Insurance
				className="place-bl"
				onMouseOver={() => setActiveWaysToSaveCompositionCard("insurance")}
				onMouseLeave={() => setActiveWaysToSaveCompositionCard(undefined)}
			/>
		),
		"bill-reduction": (
			<MoreWaysToSaveCards.BillReduction
				className="place-br"
				onMouseOver={() => setActiveWaysToSaveCompositionCard("bill-reduction")}
				onMouseLeave={() => setActiveWaysToSaveCompositionCard(undefined)}
			/>
		),
		"georgia-freeze": (
			<MoreWaysToSaveCards.GeorgiaFreeze
				className="place-bl"
				onMouseOver={() => setActiveWaysToSaveCompositionCard("georgia-freeze")}
				onMouseLeave={() => setActiveWaysToSaveCompositionCard(undefined)}
			/>
		),
		"georgia-appeal-rule": (
			<MoreWaysToSaveCards.GeorgiaAppealRule
				className="place-br"
				onMouseOver={() =>
					setActiveWaysToSaveCompositionCard("georgia-appeal-rule")
				}
				onMouseLeave={() => setActiveWaysToSaveCompositionCard(undefined)}
			/>
		),
		"new-york-exemption": (
			<MoreWaysToSaveCards.NewYorkExemption className="place-tr" />
		),
		"new-york-nassau": (
			<MoreWaysToSaveCards.NewYorkNassau className="place-bl" />
		),
		"new-york-scar": <MoreWaysToSaveCards.NewYorkScar className="place-br" />,
	};

	const carouselServiceMap: Record<MoreWaysToSaveService, JSX.Element> = {
		appeal: <MoreWaysToSaveCards.Appeal key={"appeal"} target={target} />,
		"appeal-no-click": (
			<MoreWaysToSaveCards.Appeal key={"appeal-no-click"} target={target} />
		),
		exemption: (
			<MoreWaysToSaveCards.Exemption key={"exemption"} target={target} />
		),
		insurance: <MoreWaysToSaveCards.Insurance key={"insurance"} />,
		"bill-reduction": (
			<MoreWaysToSaveCards.BillReduction key={"bill-reduction"} />
		),
		"georgia-freeze": (
			<MoreWaysToSaveCards.GeorgiaFreeze key={"georgia-freeze"} />
		),
		"georgia-appeal-rule": (
			<MoreWaysToSaveCards.GeorgiaAppealRule key={"georgia-appeal-rule"} />
		),
		"new-york-exemption": (
			<MoreWaysToSaveCards.NewYorkExemption key={"new-york-exemption"} />
		),
		"new-york-nassau": (
			<MoreWaysToSaveCards.NewYorkNassau key={"new-york-nassau"} />
		),
		"new-york-scar": <MoreWaysToSaveCards.NewYorkScar key={"new-york-scar"} />,
	};

	return (
		<div id="landing-more-ways-to-save-section">
			<p className="landing-section-title">
				{!!title ? (
					title
				) : (
					<>
						How you can <span className="kelly-gradient">save</span>
					</>
				)}
			</p>
			<div id="more-ways-to-save-comp-with-cards">
				{services.slice(0, 2).map(service => serviceMap[service])}
				<MoreWaysToSaveHouseComposition
					mobileActiveIcon={activeWaysToSaveCarouselCard}
					desktopActiveIcon={activeWaysToSaveCompositionCard}
					services={services}
				/>
				{services.slice(2, 4).map(service => serviceMap[service])}
			</div>
			<Carousel
				onIndicesChange={({ singleColumnIndices }) => {
					if (singleColumnIndices.centerKey) {
						setActiveWaysToSaveCarouselCard(
							singleColumnIndices.centerKey.toString() as MoreWaysToSaveService
						);
					}
				}}
				id="more-ways-to-save-carousel"
				initial={{
					height: 0,
					paddingBottom: 0,
				}}
				animate={{
					height: 270,
					paddingBottom: 32,
				}}
				columns={1}>
				{services.map(service => carouselServiceMap[service])}
			</Carousel>
			<div id="more-ways-to-save-carousel-pagination">
				{services.map(service => (
					<div
						key={service}
						className={[
							"pagination-dot",
							activeWaysToSaveCarouselCard === service ? "active" : "",
						].join(" ")}
					/>
				))}
			</div>
		</div>
	);
};
