import React from "react";
import Router from "next/router";
import LearnMoreArrowRight from "public/learn-more-arrow-right.svg";
import {
	stateSpecificAppealWord,
	stateSpecificExemptionWord,
} from "utils/account";

interface StateProps extends React.HTMLAttributes<HTMLElement> {
	target?: string;
	hideCta?: boolean;
}

export namespace MoreWaysToSaveCards {
	export const Appeal: React.FC<StateProps> = ({
		target,
		hideCta = false,
		...props
	}) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>
					Property Tax {stateSpecificAppealWord(target, { capitalize: true })}
				</header>
				<p className="more-ways-to-save-description">
					We manage the end-to-end process of property tax protests and{" "}
					{stateSpecificAppealWord(target, { plural: true })}. From paperwork to
					negotiations and {stateSpecificAppealWord(target)} hearings, Ownwell
					handles it all. We combine local expertise with software to build the
					best evidence to earn tax savings.
				</p>
				{!hideCta && (
					<button
						className="button-learn-more"
						onClick={() => Router.push("/appeals")}>
						<p className="learn-more-text">Learn More</p>
						<LearnMoreArrowRight />
					</button>
				)}
			</article>
		);
	};

	export const Exemption: React.FC<StateProps> = ({ target, ...props }) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>
					Property Tax{" "}
					{stateSpecificExemptionWord(target, {
						capitalize: true,
						plural: true,
					})}
				</header>
				<p className="more-ways-to-save-description">
					There are many types of property tax{" "}
					{stateSpecificExemptionWord(target, { plural: true })} from primary
					residence to senior that can reduce the taxable value of your
					property. A lower value leads to smaller tax bills. Answer a few
					questions and Ownwell will complete, file and manage your tax{" "}
					{stateSpecificExemptionWord(target, { plural: true })} to unlock more
					savings!
				</p>
				<button
					className="button-learn-more"
					onClick={() => Router.push("/exemptions")}>
					<p className="learn-more-text">Learn More</p>
					<LearnMoreArrowRight />
				</button>
			</article>
		);
	};

	export const Insurance: React.FC<React.HTMLAttributes<HTMLElement>> = ({
		...props
	}) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>Insurance Rate Monitoring</header>
				<p className="more-ways-to-save-description">
					Ownwell monitors your insurance rates and coverage to ensure you are
					getting the best deal. Answer a few questions and we will shop your
					insurance policy to find you savings. We monitor and compare rates
					regularly to ensure you aren't overpaying.
				</p>
			</article>
		);
	};

	export const BillReduction: React.FC<React.HTMLAttributes<HTMLElement>> = ({
		...props
	}) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>Bill Reduction</header>
				<p className="more-ways-to-save-description">
					Stop overpaying for your existing internet and phone bills. Share your
					statement with Ownwell and our reduction experts will work directly
					with your provider to find you savings. We monitor and compare bills
					regularly to ensure you aren't overpaying.
				</p>
			</article>
		);
	};

	export const NeighborhoodAlerts: React.FC<
		React.HTMLAttributes<HTMLElement>
	> = ({ ...props }) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>Neighborhood Alerts</header>
				<p className="more-ways-to-save-description">
					Receive timely alerts, updates and track trends for a variety of
					different events in your local area. From permits for new construction
					projects to crime and incident reports. Being aware of what’s going on
					around you can help in making important homeownership decisions.
				</p>
			</article>
		);
	};

	export const Expenses: React.FC<React.HTMLAttributes<HTMLElement>> = ({
		...props
	}) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>Local Expense Comparisons</header>
				<p className="more-ways-to-save-description">
					Get insights into how your regular monthly expenses compare to the
					averages in your neighborhood. As you gain an understanding for what
					neighbors may be spending on different aspects of their property each
					month, you’ll be better informed to course correct your
					higher-than-average bills.
				</p>
			</article>
		);
	};

	export const PropertyValueTracking: React.FC<
		React.HTMLAttributes<HTMLElement>
	> = ({ ...props }) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>Property Value Tracking</header>
				<p className="more-ways-to-save-description">
					Get the most up to date market value for your property and track the
					change in value since your purchase with robust historical data.{" "}
					Understand how your real time property value effects your investment
					with our equity to debt analysis tool, showing everything from
					interest remaining to available equity.
				</p>
			</article>
		);
	};

	export const GeorgiaFreeze: React.FC<React.HTMLAttributes<HTMLElement>> = ({
		...props
	}) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>299(c) 3 Year Valuation Freeze</header>
				<p className="more-ways-to-save-description">
					299(c) is a section of the Georgia state code that provides for a
					three-year property value freeze. This section applies when a property
					tax assessment is appealed before the Board of Equalization, hearing
					officer, arbitration, or superior court. If the appeal is settled at
					one of these locations, the assessed value is frozen for that year and
					the following two years.
				</p>
			</article>
		);
	};

	export const GeorgiaAppealRule: React.FC<
		React.HTMLAttributes<HTMLElement>
	> = ({ ...props }) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>85% Pending Appeal Rule</header>
				<p className="more-ways-to-save-description">
					When an appeal is pending, you will be issued a temporary tax bill at
					the lesser of 85% of the proposed market value or prior year's market
					value. If you are able to get a reduction below the value paid on the
					temp tax bill, the owner would be entitled to a refund for the
					difference. Otherwise you'll just pay the difference after the appeal
					is complete.
				</p>
			</article>
		);
	};

	export const NewYorkExemption: React.FC<
		React.HTMLAttributes<HTMLElement>
	> = ({ ...props }) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>Exemptions and STAR credits</header>
				<p className="more-ways-to-save-description">
					There are a variety of exemptions that can reduce your property
					assessed value and the STAR Credit is a program which provides
					eligible homeowners with a reduction on their school property taxes
					through a yearly credit.
				</p>
			</article>
		);
	};

	export const NewYorkNassau: React.FC<React.HTMLAttributes<HTMLElement>> = ({
		...props
	}) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>Nassau village assessments</header>
				<p className="more-ways-to-save-description">
					The county and village have different assessors in Nassau. We can
					grieve your village tax assessment if it would results in more savings
					for you. Keep in mind that deadlines for the county and village can
					vary.
				</p>
			</article>
		);
	};

	export const NewYorkScar: React.FC<React.HTMLAttributes<HTMLElement>> = ({
		...props
	}) => {
		return (
			<article
				{...props}
				className={"more-ways-to-save-card " + props.className}>
				<header>SCAR (small claims assessment review)</header>
				<p className="more-ways-to-save-description">
					If you don't receive a reduction at the ARC or BAR level or are
					unhappy with the results, our team can handle the SCAR process for you
					when applicable. There may be a fee involved depending on a variety of
					factors, but our team is equipped and knowledgable to support you end
					to end.
				</p>
			</article>
		);
	};
}
