import React from "react";
import MoreWaysToSaveHouse from "public/landing-more-ways-to-save/more-ways-to-save-house.svg";
import StackedCircles from "public/landing-more-ways-to-save/stacked-circles.svg";
import InsuranceMoreWaysToSave from "public/landing-more-ways-to-save/insurance-more-ways-to-save.svg";
import ExemptionsMoreWaysToSave from "public/landing-more-ways-to-save/exemptions-more-ways-to-save.svg";
import BillReductionMoreWaysToSave from "public/landing-more-ways-to-save/bill-reduction-more-ways-to-save.svg";
import AppealsMoreWaysToSave from "public/landing-more-ways-to-save/appeals-more-ways-to-save.svg";
import GeorgiaFreeze from "public/landing-more-ways-to-save/georgia-freeze.svg";
import { MoreWaysToSaveService } from "./MoreWaysToSaveSection";

export const MoreWaysToSaveHouseComposition = ({
	mobileActiveIcon,
	desktopActiveIcon,
	services,
}: {
	mobileActiveIcon: string;
	desktopActiveIcon?: string;
	services: MoreWaysToSaveService[];
}) => {
	const serviceMap: Record<MoreWaysToSaveService, JSX.Element> = {
		appeal: (
			<AppealsMoreWaysToSave
				className={
					"more-ways-to-save-icon" +
					(mobileActiveIcon === "appeal" ? " mobile-active" : "") +
					(desktopActiveIcon === "appeal" ? " desktop-active" : "")
				}
			/>
		),
		"appeal-no-click": (
			<AppealsMoreWaysToSave
				className={
					"more-ways-to-save-icon" +
					(mobileActiveIcon === "appeal-no-click" ? " mobile-active" : "") +
					(desktopActiveIcon === "appeal-no-click" ? " desktop-active" : "")
				}
			/>
		),
		exemption: (
			<ExemptionsMoreWaysToSave
				className={
					"more-ways-to-save-icon" +
					(mobileActiveIcon === "exemption" ? " mobile-active" : "") +
					(desktopActiveIcon === "exemption" ? " desktop-active" : "")
				}
			/>
		),
		insurance: (
			<InsuranceMoreWaysToSave
				className={
					"more-ways-to-save-icon" +
					(mobileActiveIcon === "insurance" ? " mobile-active" : "") +
					(desktopActiveIcon === "insurance" ? " desktop-active" : "")
				}
			/>
		),
		"bill-reduction": (
			<BillReductionMoreWaysToSave
				className={
					"more-ways-to-save-icon" +
					(mobileActiveIcon === "bill-reduction" ? " mobile-active" : "") +
					(desktopActiveIcon === "bill-reduction" ? " desktop-active" : "")
				}
			/>
		),
		"georgia-freeze": (
			<GeorgiaFreeze
				className={
					"more-ways-to-save-icon" +
					(mobileActiveIcon === "georgia-freeze" ? " mobile-active" : "") +
					(desktopActiveIcon === "georgia-freeze" ? " desktop-active" : "")
				}
			/>
		),
		"georgia-appeal-rule": (
			<BillReductionMoreWaysToSave
				className={
					"more-ways-to-save-icon" +
					(mobileActiveIcon === "georgia-appeal-rule" ? " mobile-active" : "") +
					(desktopActiveIcon === "georgia-appeal-rule" ? " desktop-active" : "")
				}
			/>
		),
		"new-york-exemption": (
			<ExemptionsMoreWaysToSave
				className={
					"more-ways-to-save-icon" +
					(mobileActiveIcon === "new-york-exemption" ? " mobile-active" : "") +
					(desktopActiveIcon === "new-york-exemption" ? " desktop-active" : "")
				}
			/>
		),
		"new-york-nassau": (
			<InsuranceMoreWaysToSave
				className={
					"more-ways-to-save-icon" +
					(mobileActiveIcon === "new-york-nassau" ? " mobile-active" : "") +
					(desktopActiveIcon === "new-york-nassau" ? " desktop-active" : "")
				}
			/>
		),
		"new-york-scar": (
			<BillReductionMoreWaysToSave
				className={
					"more-ways-to-save-icon" +
					(mobileActiveIcon === "new-york-scar" ? " mobile-active" : "") +
					(desktopActiveIcon === "new-york-scar" ? " desktop-active" : "")
				}
			/>
		),
	};

	return (
		<div id="more-ways-to-save-house-composition">
			<StackedCircles className="stacked-circles" />

			<div className="more-ways-to-save-row">
				{services.slice(0, 2).map(service => serviceMap[service])}
			</div>

			<MoreWaysToSaveHouse className="more-ways-to-save-house" />

			<div className="more-ways-to-save-row">
				{services.slice(2, 4).map(service => serviceMap[service])}
			</div>
		</div>
	);
};
