import React, { FC } from "react";
import {
	motion,
	HTMLMotionProps,
	AnimatePresence,
	Variants,
	TargetAndTransition,
	DragHandlers,
} from "framer-motion";

import CarouselCaretLeft from "public/carousel-caret-left.svg";
import CarouselCaretRight from "public/carousel-caret-right.svg";
import { useScrollDirection } from "utils/useScrollDirection";
import throttle from "lodash.throttle";

namespace Carousel {
	const duration = 0.6;

	type Indices = {
		threeColumnIndices: Array<React.Key | null>;
		singleColumnIndices: Array<React.Key | null>;
	};

	const initializeIndices = (childKeys: React.Key[]): Indices => {
		const threeColumnIndices: Array<React.Key | null> = [
			null,
			null,
			...childKeys,
		];

		const singleColumnIndices: Array<React.Key | null> = [null, ...childKeys];

		return { threeColumnIndices, singleColumnIndices };
	};

	const isReactKey = (
		possibleKey: React.Key | null
	): possibleKey is React.Key => {
		return possibleKey !== null && possibleKey !== undefined;
	};

	interface ContainerProps extends HTMLMotionProps<"div"> {
		columns: 3 | 1; // Would be nice to figure out how to take n number of columns
		onIndicesChange?: (args: {
			singleColumnIndices: {
				centerKey: React.Key | null;
			};
			threeColumnIndices: {
				centerKey: React.Key | null;
			};
		}) => void;
	}
	export const Container: FC<ContainerProps> = ({
		id,
		children,
		columns,
		initial,
		animate,
		onIndicesChange,
	}) => {
		const childKeys = React.useMemo(
			() =>
				React.Children.map(children ?? [], (child, i) => {
					if (React.isValidElement(child)) return child.key;
				}).filter(isReactKey),
			[children]
		);

		const [indices, setIndices] = React.useState<Indices>(
			initializeIndices(childKeys)
		);

		React.useEffect(() => {
			if (onIndicesChange) {
				onIndicesChange({
					singleColumnIndices: {
						centerKey: indices.singleColumnIndices?.[1] ?? null,
					},
					threeColumnIndices: {
						centerKey: indices.threeColumnIndices?.[3] ?? null,
					},
				});
			}
		}, [indices, onIndicesChange]);

		const [flowDirection, setFlowDirection] = React.useState<boolean>(true);

		React.useEffect(() => {
			const { threeColumnIndices, singleColumnIndices } = indices;

			const childKeyRemoved = threeColumnIndices.find(
				idx => idx !== null && !childKeys.includes(idx)
			);

			if (childKeyRemoved) {
				if (
					(columns === 3 &&
						threeColumnIndices.slice(-3).includes(childKeyRemoved)) ||
					(columns === 1 &&
						singleColumnIndices.slice(-1).includes(childKeyRemoved))
				) {
					if (columns === 3) {
						setFlowDirection(true);
					} else {
						setFlowDirection(false);
					}

					let newThreeColumnIndices = childKeys.slice(
						-threeColumnIndices.length
					);
					if (newThreeColumnIndices.length < 3) {
						newThreeColumnIndices = [
							...Array(2).fill(null),
							...newThreeColumnIndices,
						];
					} else if (newThreeColumnIndices.length < 5) {
						newThreeColumnIndices = [
							...Array(5 - newThreeColumnIndices.length).fill(null),
							...newThreeColumnIndices,
						];
					}

					let newSingleColumnIndices = childKeys.slice(-2);
					if (newSingleColumnIndices.length < 2) {
						newSingleColumnIndices = [
							...Array(2 - newSingleColumnIndices.length).fill(null),
							...newSingleColumnIndices,
						];
					}

					setIndices(i => {
						return {
							threeColumnIndices: newThreeColumnIndices,
							singleColumnIndices: newSingleColumnIndices,
						};
					});
				} else {
					setFlowDirection(true);
					let newThreeColumnIndices = threeColumnIndices.filter(
						d => d === null || childKeys.includes(d)
					);

					let newSingleColumnIndices = singleColumnIndices.filter(
						d => d === null || childKeys.includes(d)
					);
					setIndices(i => {
						return {
							threeColumnIndices: newThreeColumnIndices,
							singleColumnIndices: newSingleColumnIndices,
						};
					});
				}
			}
		}, [columns, indices, childKeys]);

		const threeColumnLayout = useThreeColumnLayout({
			indices: indices.threeColumnIndices,
			flowDirection,
			children,
		});

		const singleColumnLayout = useSingleColumnLayout({
			indices: indices.singleColumnIndices,
			flowDirection,
			children,
		});

		const nextBtnDisplayed = React.useMemo(() => {
			if (columns === 3) {
				return !!indices.threeColumnIndices[5];
			} else {
				return !!indices.singleColumnIndices[2];
			}
		}, [indices, columns]);

		const nextBtnEnabled = React.useMemo(
			() => nextBtnDisplayed,
			// && !singleColumnLayout.isAnimating
			// && !threeColumnLayout.isAnimating
			[
				nextBtnDisplayed,
				singleColumnLayout.isAnimating,
				threeColumnLayout.isAnimating,
			]
		);

		const nextBtn = React.useCallback(() => {
			if (!nextBtnEnabled) return;

			setIndices(i => {
				const { threeColumnIndices, singleColumnIndices } = i;
				return {
					threeColumnIndices: threeColumnIndices.slice(1),
					singleColumnIndices: singleColumnIndices.slice(1),
				};
			});

			setFlowDirection(true);
		}, [nextBtnEnabled]);

		const prevBtnDisplayed = React.useMemo(() => {
			if (columns === 3) {
				return !!indices.threeColumnIndices[1];
			} else {
				return !!indices.singleColumnIndices[0];
			}
		}, [indices, columns]);

		const prevBtnEnabled = React.useMemo(
			() => prevBtnDisplayed,
			// && !singleColumnLayout.isAnimating
			// && !threeColumnLayout.isAnimating
			[
				prevBtnDisplayed,
				singleColumnLayout.isAnimating,
				threeColumnLayout.isAnimating,
			]
		);

		const prevBtn = React.useCallback(() => {
			if (!prevBtnEnabled) return;
			setIndices(i => {
				const { threeColumnIndices, singleColumnIndices } = i;

				let newThreeColumnIndices;
				const stackedFarLeft = threeColumnIndices[0];
				const stackedLeft = threeColumnIndices[1];
				if (!stackedLeft) {
					newThreeColumnIndices = threeColumnIndices;
				} else if (!stackedFarLeft) {
					newThreeColumnIndices = [null, ...threeColumnIndices];
				} else {
					const k = childKeys.indexOf(stackedFarLeft);
					newThreeColumnIndices = [
						childKeys[k - 1] ?? null,
						...threeColumnIndices,
					];
				}

				let newSingleColumnIndices;
				const leftOut = singleColumnIndices[0];
				if (!leftOut) {
					newSingleColumnIndices = singleColumnIndices;
				} else {
					const k = childKeys.indexOf(leftOut);
					newSingleColumnIndices = [
						childKeys[k - 1] ?? null,
						...singleColumnIndices,
					];
				}

				return {
					threeColumnIndices: newThreeColumnIndices,
					singleColumnIndices: newSingleColumnIndices,
				};
			});
			setFlowDirection(false);
		}, [childKeys, prevBtnEnabled]);

		const scrollRef = React.useRef<HTMLDivElement>(null);
		React.useEffect(() => {
			if (scrollRef.current) {
				const { scrollWidth, clientWidth } = scrollRef.current;
				const centerPosition = (scrollWidth - clientWidth) / 2;
				scrollRef.current.scrollLeft = centerPosition;
			}
		}, [scrollRef]);
		const handleScroll: React.UIEventHandler<HTMLDivElement> =
			React.useCallback(
				event => {
					const { scrollLeft, scrollWidth, clientWidth } = event.currentTarget;
					const centerPosition = (scrollWidth - clientWidth) / 2;

					const delta = Math.abs(scrollLeft - centerPosition);

					if (delta > 80) {
						if (scrollLeft < centerPosition) {
							prevBtn();
						}

						if (scrollLeft > centerPosition) {
							nextBtn();
						}
					}

					if (scrollRef.current) {
						scrollRef.current.scrollLeft = centerPosition;
					}
				},
				[nextBtn, prevBtn]
			);

		const dragConstraintsRef = React.useRef(null);
		const handleDrag: DragHandlers["onDrag"] = (event, info) => {
			const { delta } = info;
			if (delta.x > 0) {
				prevBtn();
			} else if (delta.x < 0) {
				nextBtn();
			}
		};

		const collapseAnimation = {
			height: 0,
			margin: 0,
			padding: 0,
			transition: {
				duration,
				type: "spring",
			},
		};

		return (
			<motion.div
				id={id}
				// scroll is disabled for now
				// onScroll={handleScroll}
				// ref={scrollRef}
				className="carousel-wrapper"
				initial={initial}
				animate={childKeys.length === 0 ? collapseAnimation : animate}>
				{<div className="carousel-scroll" />}
				<motion.div className="carousel-content" ref={dragConstraintsRef}>
					<motion.ul
						className="carousel-items"
						drag={"x"}
						dragElastic={0.05}
						dragConstraints={dragConstraintsRef}
						dragTransition={{ bounceStiffness: 500, bounceDamping: 40 }}
						onDragStart={handleDrag}>
						{columns === 3 && (
							<AnimatePresence initial={true}>
								{threeColumnLayout.StackedFarLeftComponent}
								{threeColumnLayout.StackedLeftComponent}
								{threeColumnLayout.LeftComponent}
								{threeColumnLayout.CenterComponent}
								{threeColumnLayout.RightComponent}
								{threeColumnLayout.StackedRightComponent}
								{threeColumnLayout.StackedFarRightComponent}
							</AnimatePresence>
						)}
						{columns === 1 && (
							<AnimatePresence initial={true}>
								{singleColumnLayout.LeftOutComponent}
								{singleColumnLayout.CenterComponent}
								{singleColumnLayout.StackedRightComponent}
								{singleColumnLayout.StackedFarRightComponent}
							</AnimatePresence>
						)}
					</motion.ul>
					<AnimatePresence initial={true}>
						{prevBtnDisplayed && (
							<motion.button
								key="prev"
								initial={{ opacity: 0, scale: 0 }}
								animate={
									prevBtnEnabled
										? { opacity: 1, scale: 1, x: 2 }
										: { opacity: 0.4, scale: 1, x: 2 }
								}
								exit={{ opacity: 0, scale: 0, x: 0 }}
								transition={{
									type: "spring",
									duration,
								}}
								whileHover={{ scale: 1.1, x: 2 }}
								whileTap={{ scale: 0.8 }}
								className="carousel-btn carousel-prev"
								onClick={prevBtn}>
								<CarouselCaretLeft />
							</motion.button>
						)}
						{nextBtnDisplayed && (
							<motion.button
								key="next"
								initial={{ opacity: 0, scale: 0 }}
								animate={
									nextBtnEnabled
										? { opacity: 1, scale: 1, x: -2 }
										: { opacity: 0.4, scale: 1, x: -2 }
								}
								exit={{ opacity: 0, scale: 0, x: 0 }}
								transition={{
									type: "spring",
									duration,
								}}
								whileHover={{ scale: 1.1, x: -2 }}
								whileTap={{ scale: 0.8 }}
								className="carousel-btn carousel-next"
								onClick={nextBtn}>
								<CarouselCaretRight />
							</motion.button>
						)}
					</AnimatePresence>
				</motion.div>
			</motion.div>
		);
	};

	interface useThreeColumnLayoutProps {
		indices: Array<React.Key | null>;
		children: React.ReactNode;
		flowDirection: boolean;
	}
	const useThreeColumnLayout = ({
		indices,
		flowDirection,
		children,
	}: useThreeColumnLayoutProps) => {
		const exitVariant: TargetAndTransition = {
			width: "calc(33% - 35px)",
			height: "100%",
			top: "-150px",
			opacity: 0,
			zIndex: 1,
			transition: {
				type: "spring",
				duration,
			},
		};

		const variants: Variants = {
			stackedFarLeftHidden: (custom, current, velocity) => {
				const target: TargetAndTransition = {
					width: "calc(33% - 35px)",
					left: "24px",
					height: "75%",
					top: "16px",
					zIndex: "-3",
					opacity: 0,
					transition: {
						type: "spring",
						duration,
					},
				};

				return target;
			},
			stackedFarLeft: (custom, current, velocity) => {
				const target: TargetAndTransition = {
					width: "calc(33% - 35px)",
					left: "12px",
					height: "77%",
					top: "16px",
					opacity: 0.75,
					zIndex: -2,
					transition: {
						type: "spring",
						duration,
					},
				};

				return target;
			},
			stackedLeft: (custom, current, velocity) => {
				const { flowDirection } = custom;

				const target: TargetAndTransition = {
					width: "calc(33% - 35px)",
					left: "24px",
					height: "88%",
					top: "8px",
					opacity: 1,
					zIndex: -1,
					transition: {
						type: "spring",
						duration,
					},
				};

				return target;
			},
			left: (custom, current, velocity) => {
				const { flowDirection } = custom;

				const target: TargetAndTransition = {
					width: "calc(33% - 35px)",
					left: "36px",
					height: "100%",
					top: "0px",
					opacity: 1,
					zIndex: 0,
					transition: {
						type: "spring",
						duration,
					},
				};

				return target;
			},
			center: {
				width: "calc(33% - 35px)",
				left: "calc(33% + 24px)",
				height: "100%",
				top: "0px",
				opacity: 1,
				zIndex: 0,
				transition: {
					type: "spring",
					duration,
				},
			},
			right: (custom, current, velocity) => {
				const { flowDirection } = custom;

				const target: TargetAndTransition = {
					width: "calc(33% - 35px)",
					left: "calc(66% + 12px)",
					height: "100%",
					top: "0px",
					opacity: 1,
					transition: {
						type: "spring",
						duration,
					},
				};

				if (!flowDirection) {
					target.zIndex = 0;
				} else {
					target.transitionEnd = {
						zIndex: 0,
					};
				}

				return target;
			},
			stackedRight: (custom, current, velocity) => {
				const { flowDirection } = custom;

				const target: TargetAndTransition = {
					width: "calc(33% - 35px)",
					left: "calc(66% + 24px)",
					height: "88%",
					top: "8px",
					opacity: 1,
					transition: {
						type: "spring",
						duration,
					},
				};

				if (!flowDirection) {
					target.zIndex = -1;
				} else {
					target.transitionEnd = {
						zIndex: -1,
					};
				}

				return target;
			},
			stackedFarRight: (custom, current, velocity) => {
				const { flowDirection } = custom;

				const target: TargetAndTransition = {
					width: "calc(33% - 35px)",
					left: "calc(66% + 36px)",
					height: "77%",
					top: "16px",
					opacity: 0.75,
					transition: {
						type: "spring",
						duration,
					},
				};

				if (!flowDirection) {
					target.zIndex = -2;
				} else {
					target.transitionEnd = {
						zIndex: -2,
					};
				}

				return target;
			},
			stackedFarRightHidden: (custom, current, velocity) => {
				const { flowDirection } = custom;

				const target: TargetAndTransition = {
					width: "calc(33% - 35px)",
					left: "calc(66% + 24px)",
					height: "75%",
					top: "16px",
					opacity: 0,
					transition: {
						type: "spring",
						duration,
					},
				};

				if (!flowDirection) {
					target.zIndex = -3;
				} else {
					target.transitionEnd = {
						zIndex: -3,
					};
				}

				return target;
			},
		};

		const [animationStatuses, setAnimationStatuses] = React.useState<{
			StackedFarLeftComponent: "animating" | null;
			StackedLeftComponent: "animating" | null;
			LeftComponent: "animating" | null;
			CenterComponent: "animating" | null;
			RightComponent: "animating" | null;
			StackedRightComponent: "animating" | null;
			StackedFarRightComponent: "animating" | null;
		}>({
			StackedFarLeftComponent: null,
			StackedLeftComponent: null,
			LeftComponent: null,
			CenterComponent: null,
			RightComponent: null,
			StackedRightComponent: null,
			StackedFarRightComponent: null,
		});

		const isAnimating = React.useMemo(
			() => Object.values(animationStatuses).some(v => v === "animating"),
			[animationStatuses]
		);

		const StackedFarLeftComponent = React.useMemo(() => {
			const key = indices[0];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({
							...s,
							StackedFarLeftComponent: "animating",
						}))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({ ...s, StackedFarLeftComponent: null }))
					}
					initial={flowDirection ? "stackedLeft" : "stackedFarLeftHidden"}
					animate="stackedFarLeft"
					custom={{ flowDirection }}
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices[0]]);

		const StackedLeftComponent = React.useMemo(() => {
			const key = indices[1];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({
							...s,
							StackedLeftComponent: "animating",
						}))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({ ...s, StackedLeftComponent: null }))
					}
					initial={flowDirection ? "left" : "stackedFarLeft"}
					animate="stackedLeft"
					custom={{ flowDirection }}
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices[1]]);

		const LeftComponent = React.useMemo(() => {
			const key = indices[2];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({ ...s, LeftComponent: "animating" }))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({ ...s, LeftComponent: null }))
					}
					initial={flowDirection ? "center" : "stackedLeft"}
					animate="left"
					exit={exitVariant}
					custom={{ flowDirection }}
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices[2]]);

		const CenterComponent = React.useMemo(() => {
			const key = indices[3];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({ ...s, CenterComponent: "animating" }))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({ ...s, CenterComponent: null }))
					}
					initial={flowDirection ? "right" : "left"}
					custom={{ flowDirection: flowDirection }}
					animate="center"
					exit={exitVariant}
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices[3]]);

		const RightComponent = React.useMemo(() => {
			const key = indices[4];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({ ...s, RightComponent: "animating" }))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({ ...s, RightComponent: null }))
					}
					initial={flowDirection ? "stackedRight" : "center"}
					animate="right"
					exit={exitVariant}
					custom={{ flowDirection: flowDirection }}
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices[4]]);

		const StackedRightComponent = React.useMemo(() => {
			const key = indices[5];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({
							...s,
							StackedRightComponent: "animating",
						}))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({ ...s, StackedRightComponent: null }))
					}
					initial={flowDirection ? "stackedFarRight" : "right"}
					animate="stackedRight"
					custom={{ flowDirection: flowDirection }}
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices[5]]);

		const StackedFarRightComponent = React.useMemo(() => {
			const key = indices[6];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({
							...s,
							StackedFarRightComponent: "animating",
						}))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({
							...s,
							StackedFarRightComponent: null,
						}))
					}
					initial={flowDirection ? "stackedFarRightHidden" : "stackedRight"}
					animate="stackedFarRight"
					custom={{ flowDirection }}
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices[6]]);

		return {
			isAnimating,
			StackedFarLeftComponent,
			StackedLeftComponent,
			LeftComponent,
			CenterComponent,
			RightComponent,
			StackedRightComponent,
			StackedFarRightComponent,
		};
	};

	interface useSingleColumnLayoutProps {
		indices: Array<React.Key | null>;
		children: React.ReactNode;
		flowDirection: boolean;
	}
	const useSingleColumnLayout = ({
		indices,
		children,
		flowDirection,
	}: useSingleColumnLayoutProps) => {
		const exitVariant: TargetAndTransition = {
			top: "-150px",
			height: "100%",
			opacity: 0,
			zIndex: 2,
			transition: {
				type: "spring",
				duration,
			},
		};

		const variants: Variants = {
			leftOut: (custom, current, velocity) => {
				const { flowDirection } = custom;
				const target: TargetAndTransition = {
					width: "100%",
					left: "-100%",
					height: "100%",
					top: "0px",
					zIndex: 1,
					opacity: 0,
					transition: {
						type: "spring",
						duration,
					},
				};

				if (!flowDirection) {
					target.transitionEnd = {
						zIndex: -1,
					};
				}

				return target;
			},
			center: {
				width: "calc(100% - 60px)",
				left: "30px",
				height: "100%",
				top: "0px",
				opacity: 1,
				zIndex: 0,
				transition: {
					type: "spring",
					duration,
				},
			},
			stackedRight: (custom, current, velocity) => {
				const { flowDirection } = custom;

				const target: TargetAndTransition = {
					width: "calc(100% - 60px)",
					left: "38px",
					height: "87%",
					top: "8px",
					opacity: 1,
					transition: {
						type: "spring",
						duration,
					},
				};

				if (!flowDirection) {
					target.zIndex = -1;
				} else {
					target.transitionEnd = {
						zIndex: -1,
					};
				}

				return target;
			},
			stackedFarRight: (custom, current, velocity) => {
				const { flowDirection } = custom;

				const target: TargetAndTransition = {
					width: "calc(100% - 60px)",
					left: "46px",
					height: "74%",
					top: "16px",
					opacity: 0.75,
					transition: {
						type: "spring",
						duration,
					},
				};

				if (!flowDirection) {
					target.zIndex = -2;
				} else {
					target.transitionEnd = {
						zIndex: -2,
					};
				}

				return target;
			},
			stackedFarRightHidden: (custom, current, velocity) => {
				const { flowDirection } = custom;

				const target: TargetAndTransition = {
					left: "calc(66% + 24px)",
					height: "75%",
					top: "16px",
					opacity: 0,
					transition: {
						type: "spring",
						duration,
					},
				};

				if (!flowDirection) {
					target.zIndex = -3;
				} else {
					target.transitionEnd = {
						zIndex: -3,
					};
				}

				return target;
			},
		};

		const [animationStatuses, setAnimationStatuses] = React.useState<{
			LeftOutComponent: "animating" | null;
			CenterComponent: "animating" | null;
			StackedRightComponent: "animating" | null;
			StackedFarRightComponent: "animating" | null;
		}>({
			LeftOutComponent: null,
			CenterComponent: null,
			StackedRightComponent: null,
			StackedFarRightComponent: null,
		});

		const isAnimating = React.useMemo(
			() => Object.values(animationStatuses).some(v => v === "animating"),
			[animationStatuses]
		);

		const LeftOutComponent = React.useMemo(() => {
			const key = indices[0];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({ ...s, LeftOutComponent: "animating" }))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({ ...s, LeftOutComponent: null }))
					}
					initial={flowDirection ? "center" : "leftOut"}
					custom={{ flowDirection }}
					animate="leftOut"
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices]);

		const CenterComponent = React.useMemo(() => {
			const key = indices[1];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({ ...s, CenterComponent: "animating" }))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({ ...s, CenterComponent: null }))
					}
					initial={flowDirection ? "stackedRight" : "leftOut"}
					custom={{ flowDirection }}
					animate="center"
					exit={exitVariant}
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices]);

		const StackedRightComponent = React.useMemo(() => {
			const key = indices[2];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({
							...s,
							StackedRightComponent: "animating",
						}))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({ ...s, StackedRightComponent: null }))
					}
					initial={flowDirection ? "stackedFarRight" : "center"}
					animate="stackedRight"
					custom={{ flowDirection: flowDirection }}
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices]);

		const StackedFarRightComponent = React.useMemo(() => {
			const key = indices[3];
			if (!key) return null;
			const C = React.Children.toArray(children).find(
				c => React.isValidElement(c) && c.key && c.key === `.$${key}`
			);
			if (!C) return null;
			return (
				<motion.li
					key={key}
					variants={variants}
					onAnimationStart={() =>
						setAnimationStatuses(s => ({
							...s,
							StackedFarRightComponent: "animating",
						}))
					}
					onAnimationComplete={() =>
						setAnimationStatuses(s => ({
							...s,
							StackedFarRightComponent: null,
						}))
					}
					initial={flowDirection ? "stackedFarRightHidden" : "stackedRight"}
					animate="stackedFarRight"
					custom={{ flowDirection }}
					className="carousel-item">
					{C}
				</motion.li>
			);
		}, [indices]);

		return {
			isAnimating,
			LeftOutComponent,
			CenterComponent,
			StackedRightComponent,
			StackedFarRightComponent,
		};
	};
}

export default Carousel.Container;
